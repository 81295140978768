import {GvfService} from "../../gvf/js/gvf-service";

class FcmService{
    constructor(){

    }

    sendTokenToServer(currentToken){
        if(!this.isTokenSentToServer()){
            GvfService.endpoint("/cp/cp-device/register-token",{token: currentToken}).then(
                () => {
                    this.setTokenSentToServer(true);
                }
            );
        }
    }

    isTokenSentToServer(){
        return window.localStorage.getItem("sentToServer")==="1";
    }

    setTokenSentToServer(sent){
        window.localStorage.setItem("sentToServer",sent?"1":"0");
    }

    requestPermission(){
        Notification.requestPermission().then(() => {
            this.messaging.getToken().then(
                (refreshedToken) => {
                    this.setTokenSentToServer(false);
                    this.sendTokenToServer(refreshedToken);
                }
            ).catch(
                ()=>{

                }
            );
        });
    }

    init(vapidKey){
        import("firebase/app").then(
            ({ default: firebase })=>{
                import("firebase/messaging").then(
                    ({ default: firebase2 })=>{
                        const firebaseConfig = {
                            apiKey: "AIzaSyAQAzDYid3Igw_JY_HE7i5jotrvFF9mrtM",
                            authDomain: "goventure-base-project.firebaseapp.com",
                            projectId: "goventure-base-project",
                            storageBucket: "goventure-base-project.appspot.com",
                            messagingSenderId: "124893919042",
                            appId: "1:124893919042:web:6994b539c0b762bf9c7adb"
                        };
                        firebase.initializeApp(firebaseConfig);

                        try{
                            this.messaging = firebase.messaging();
                            this.messaging.usePublicVapidKey(vapidKey);
                            this.messaging.onTokenRefresh(
                                () => {
                                    this.messaging.getToken().then(
                                        (refreshedToken) => {
                                            this.setTokenSentToServer(false);
                                            this.sendTokenToServer(refreshedToken);
                                        }).catch(function(err){
                                    });
                                }
                            );
                            this.messaging.onMessage(
                                function(payload){
                                    // Let's check if the browser supports notifications
                                    if(!("Notification" in window)){
                                        alert("This browser does not support desktop notification");
                                    }

                                    // Let's check whether notification permissions have already been granted
                                    else if(Notification.permission==="granted"){
                                        // If it's okay let's create a notification
                                        let notification = new Notification("Hi there!");
                                    }

                                    // Otherwise, we need to ask the user for permission
                                    else if(Notification.permission!=="denied"){
                                        Notification.requestPermission().then(function(permission){
                                            // If the user accepts, let's create a notification
                                            if(permission==="granted"){
                                                let notification = new Notification("Hi there!");
                                            }
                                        });
                                    }
                                    // At last, if the user has denied notifications, and you
                                    // want to be respectful there is no need to bother them any more.
                                }
                            );
                            this.requestPermission();
                        }catch(e){
                            console.log(e);
                        }
                    }
                );
            }
        );
    }
}

const s_FcmService = new FcmService();
export {s_FcmService as FcmService};