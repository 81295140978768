import {Component} from "../../../gvf/js/component";
import {GvfService} from "../../../gvf/js/gvf-service";
import {GvfUiService} from "../../../gvf/js/gvf-ui-service";

export class FormField extends Component{
    //Shows errors on field level field
    showFieldError(errors){
        const $error = this.findMember("error");
        if($.isArray(errors)){
            errors = errors.join("<br />");
        }

        $error.html("");
        this.$root.toggleClass("has-error",!!errors);
        if(errors){
            $error.html(errors);
        }
    }

    /**
     * Set required state
     * @param {boolean} isRequired
     */
    setRequired(isRequired){
        this.$root.toggleClass("is-required",isRequired);
        this.$root.find(":input").prop("required",isRequired);
        let r;
        if(isRequired){
            r = this.$root.data("restrictions") || {};
            r.required = {errorMessage: "Este campo es requerido"};
            r.required.contextualErrorMessage = r.required.errorMessage;
        }else{
            r = this.$root.data("restrictions") || {};
            delete r.required;
        }
        this.$root.data("restrictions",r);
    }

    /**
     * Set enabled state
     * @param {boolean} isEnabled
     */
    setEnabled(isEnabled){
        this.$root.toggleClass("is-disabled",!isEnabled);
        this.$root.find(":input").prop("disabled",!isEnabled);
    }

    /**
     * Show/hide field
     * @param {boolean} isVisible
     */
    setVisible(isVisible){
        if(isVisible){
            this.$root.show();
        }else{
            this.$root.hide();
        }
    }

    /**
     * Applies binding action to given element
     * @param {string} action
     * @param {any} binding
     */
    applyBindingAction(action,binding){
        const wasVisible = this.$root.is(":visible");
        const wasDisabled = this.$root.hasClass("is-disabled");
        const wasRequired = this.$root.hasClass("is-required");
        switch(action){
            case "show":
                if(!this.$root.data("lang")){ //multi-lang fields never show on binding
                    this.setVisible(true);
                }
                if(this.$root.data("wasrequired")){ //if field was required, require it again
                    this.setRequired(true);
                }
                if(!wasVisible){
                    this.$root.trigger("formField:bindShow");
                }
                break;
            case "hide":
                this.setVisible(false);
                //hidden fields cannot be required
                this.$root.data("wasrequired",wasRequired);
                this.setRequired(false);
                if(wasVisible){
                    this.$root.trigger("formField:bindHide");
                }
                break;
            case "enable":
                this.setEnabled(true);
                if(wasDisabled){
                    this.$root.trigger("formField:bindEnable");
                }
                break;
            case "disable":
                this.setEnabled(false);
                if(!wasDisabled){
                    this.$root.trigger("formField:bindDisable");
                }
                break;
            case "require":
                this.setRequired(true);
                if(!wasRequired){
                    this.$root.trigger("formField:bindRequire");
                }
                break;
            case "optional":
                this.setRequired(false);
                if(wasRequired){
                    this.$root.trigger("formField:bindOptional");
                }
                break;
            case "setvalue":
                if(binding){
                    if(binding.setvalue!==undefined && this.getVal()!=binding.setvalue){
                        this.setVal(binding.setvalue);
                        this.$root.trigger("formField:bindSetValue");
                    }
                }
                break;
        }
    }

    /**
     * Applies binding action to given element
     * @param {string} action
     * @param {any} binding
     * @param {jQuery?} $targetElement
     */
    applyBindingActionToChild($targetElement,action,binding){
        switch(action){
            case "show":
                $targetElement.show();
                break;
            case "hide":
                $targetElement.hide();
                if($targetElement.prop("selected") || $targetElement.hasClass("is-selected")){
                    this.setVal("");
                }
                break;
            case "enable":
                $targetElement.removeClass("is-disabled");
                $targetElement.prop("disabled",false);
                break;
            case "disable":
                $targetElement.addClass("is-disabled");
                if($targetElement.prop("selected") || $targetElement.hasClass("is-selected")){ //was the selected option: empty selection
                    this.setVal("");
                }
                $targetElement.prop("disabled",true);
                break;
            case "require":
            case "optional":
                throw "Cannot set as required/optional child items";
            case "setvalue":
                throw "Cannot set value on child items";
        }
    }

    /**
     * Checks field restrictions
     * @param {boolean} contextual
     * @returns {Promise<boolean>} If field has errors
     */
    async checkFieldRestrictions(contextual){
        const $field = this.$root;
        const validation = $field.data("restrictions");
        if(!$.isPlainObject(validation)){
            return true;
        }
        const errField = contextual?"contextualErrorMessage":"errorMessage";
        const val = $field.formFieldVal();
        const errors = [];
        if(validation.required){
            if(!val || val.length==0){
                errors.push(validation.required[errField]);
            }
        }
        if(validation.regexp){
            if(val && val.length>0){
                const regexp = new RegExp(validation.regexp.regexp);
                if(!regexp.test(val)){
                    errors.push(validation.regexp[errField]);
                }
            }
        }
        if(validation.callback){
            if(val && val.length>0){
                const additionalParams = validation.callback.additionalParams;
                const params = {};
                params.value = val;
                if(additionalParams){
                    for(let name in additionalParams){
                        params[name] = additionalParams[name];
                    }
                }
                const response = await GvfService.endpoint(validation.callback.endpointUrl,params);
                if(!response.result){
                    errors.push(response.message);
                }
            }
        }
        if(validation.length){
            if(val && val.length>0){
                if(val.length>validation.length.max){
                    errors.push(validation.length[errField]);
                }
            }
        }
        if(validation.range){
            if(val && val.length>0){
                if(val<validation.range.min || val>validation.range.max){
                    errors.push(validation.range[errField]);
                }
            }
        }
        if(validation["min-value"]){
            if(val && val.length>0){
                if(parseFloat(val)<parseFloat(validation["min-value"].min)){
                    errors.push(validation["min-value"][errField]);
                }
            }
        }
        if(validation["max-value"]){
            if(val && val.length>0){
                if(parseFloat(val)>parseFloat(validation["max-value"].max)){
                    errors.push(validation["max-value"][errField]);
                }
            }
        }
        this.showFieldError(errors);
        return (errors.length==0);
    }

    /**
     * Initializes lang switcher in field
     */
    initMultiLangSwitcher(){
        const $field = this.$root;
        const languages = $field.data("langs");
        const langValues = $field.data("lang-values");
        const currentFieldName = $field.data("name");
        let icons = "";
        //add cloned field for each lang with name +_lang_xx and set value
        for(let i in languages){
            const lang = languages[i];
            const $langField = $field.clone();
            const newName = currentFieldName+"_lang_"+lang;
            $langField.data("langs",null);
            $field.data("lang-"+lang+"-field",$langField);
            $langField.attr("data-lang",lang);
            $langField.find("[name='"+currentFieldName+"']").attr("name",newName);
            //in case it exists
            $langField.find("[name='"+currentFieldName+"__rich-editor']").attr("name",newName+"__rich-editor");
            $langField.find("[data-input='"+currentFieldName+"']").attr("data-input",newName);
            //change duplicate ids
            $langField.find("[id]").each(
                (index,item) => {
                    $(item).attr("id",newName+$(item).attr("id"));
                }
            );

            $langField.insertAfter($field);
            GvfUiService.init($langField);
            $langField.hide();
            $langField.setRequireField(false);
            $langField.formFieldSetVal("");
            if(langValues && langValues[lang]){
                $langField.formFieldSetVal(langValues[lang]);
            }
            $langField.prepend("<i class=\"flag-icon flag-icon-"+lang+"\"></i> ");

            icons += "<i class=\"cp-form-field__lang-switcher-icon "+this.scope.substring(1)+"__lang-switcher-icon flag-icon flag-icon-"+lang+"\" data-lang=\""+lang+"\"></i> ";
        }
        $field.prepend($("<div />").addClass("cp-form-field__lang-switcher").html(icons));
        $field.find(this.scope+"__lang-switcher-icon").click(
            (ev) => {
                const thisLang = $(ev.currentTarget).data("lang");
                const $langField = $field.data("lang-"+thisLang+"-field");
                $langField.toggle();
                if($langField.is(":visible")){ //hide other languages fields
                    for(let i in languages){
                        if(thisLang!=languages[i]){
                            $field.data("lang-"+languages[i]+"-field").hide();
                        }
                    }
                }
            }
        ).each(
            (index,item) => {
                $(item).css("opacity",langValues && langValues[$(item).data("lang")]?1:0.6);
            }
        );
    }

    /**
     * Sets field current value (unserialized)
     * @param {any} val
     * @param {boolean?} noEvent
     */
    setVal(val,noEvent){
        throw "setVal method must be implemented in "+this.scope;
    }

    /**
     * Gets field current value (unserialized)
     * @return {any}
     */
    getVal(){
        console.log(this.$root);
        throw "getVal method must be implemented in "+this.scope;
    }

    ready(){
        this.$root.on(
            "formField:checkRestrictions formField:changeVal",
            () => {
                this.checkFieldRestrictions(true);
            }
        );

        this.$root.find(":input").on(
            "invalid",
            (ev) => {
                ev.preventDefault();
                this.checkFieldRestrictions(true).then(
                    (restrictionsPassed) => {
                        if(!ev.target.validity.valid && restrictionsPassed){
                            this.showFieldError(ev.target.validationMessage);
                        }
                    }
                );
            }
        );

        if(this.$root.data("langs")){
            this.initMultiLangSwitcher();
        }
    }
}

const scope = ".js-form-field";
//component is registered in child form-fields
jQuery.fn.extend(
    {
        /**
         * @return {any}
         */
        "formFieldVal": function(){
            if(this.is(scope)){
                return this.component().getVal();
            }
            return undefined;
        },
        /**
         * @param {any} val
         */
        "formFieldSetVal": function(val){
            if(this.is(scope)){
                this.component().setVal(val);
            }
        },
        /**
         * @param {boolean} contextual
         * @return {Promise<boolean>}
         */
        "formFieldCheckRestrictions": function(contextual){
            if(this.is(scope)){
                return this.component().checkFieldRestrictions(contextual);
            }
            return false;
        },
        /**
         * @param {string[]} errors
         */
        "formFieldShowError": function(errors){
            if(this.is(scope)){
                this.component().showFieldError(errors);
            }
        },
        /**
         * Searches for a field with given name
         * @param {string} fieldName
         * @return {jQuery}
         */
        "findFormFieldByName": function(fieldName){
            return this.find(scope+"[data-name='"+fieldName+"']");
        },
        "setRequireField": function(isRequired){
            if(this.is(scope)){
                this.component().setRequired(isRequired);
            }
        }
    }
);
